import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

// ----------------------------------------------------------------------

export default function LoadingScreen({
  sx,
  ...other
}: BoxProps) {
  return <Box sx={{
    px: 5,
    width: 1,
    flexGrow: 1,
    minHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...sx
  }} {...other} data-sentry-element="Box" data-sentry-component="LoadingScreen" data-sentry-source-file="loading-screen.tsx">
      <LinearProgress color="inherit" sx={{
      width: 1,
      maxWidth: 360
    }} data-sentry-element="LinearProgress" data-sentry-source-file="loading-screen.tsx" />
    </Box>;
}